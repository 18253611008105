body.drawer-navbar.drawer-fixed {
  padding-top: 0; }

.drawer-main {
  position: fixed;
  z-index: 5300;
  top: 0;
  overflow: hidden;
  width: 280px;
  height: 100%; }
  .drawer-left .drawer-main {
    left: -280px;
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate3d(-280px, 0px, 0px); }
  .drawer-left.drawer-open .drawer-main {
    left: 0;
    transform: translate3d(0px, 0px, 0px); }
  .drawer-right .drawer-main {
    right: -280px;
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate3d(280px, 0px, 0px); }
  .drawer-right.drawer-open .drawer-main {
    right: 0;
    transform: translate3d(0px, 0px, 0px); }
  .drawer-top .drawer-main {
    width: 100%;
    height: auto;
    max-height: 100%;
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate3d(0, -100%, 0px); }
  .drawer-top.drawer-open .drawer-main {
    transform: translate3d(0, 0, 0px); }

.drawer-overlay {
  position: relative; }

.drawer-left .drawer-hamburger {
  left: 0;
  transform: translateX(0px) translateY(0px); }

.drawer-left.drawer-open .drawer-hamburger {
  transform: translateX(280px) translateY(0px); }

.drawer-right .drawer-hamburger {
  right: 0;
  transform: translateX(0px) translateY(0px); }

.drawer-right.drawer-open .drawer-hamburger {
  transform: translateX(-280px) translateY(0px); }

.drawer-top .drawer-hamburger {
  right: 0; }

.drawer-top.drawer-open .drawer-hamburger {
  z-index: 5400;
  right: 0; }

.drawer-overlay-upper {
  position: fixed;
  z-index: 5200;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5); }
  .drawer-open .drawer-overlay-upper {
    display: block !important; }

.drawer-hamburger {
  border: 0;
  background: 0;
  position: fixed;
  display: block;
  top: 0;
  padding: 20px;
  width: 70px;
  height: 70px;
  background-color: transparent;
  border: 0;
  z-index: 5100;
  outline: 0; }
  .drawer-hamburger:hover {
    cursor: pointer; }

.drawer-hamburger-icon {
  position: relative;
  display: block;
  margin-top: 10px; }
  .drawer-hamburger-icon, .drawer-hamburger-icon:before, .drawer-hamburger-icon:after {
    height: 3px;
    width: 100%;
    border-radius: 1px;
    background-color: #ddd;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1); }
  .drawer-hamburger-icon:before, .drawer-hamburger-icon:after {
    position: absolute;
    content: " ";
    top: -10px;
    left: 0; }
  .drawer-hamburger-icon:after {
    top: 10px; }
  .drawer-open .drawer-hamburger-icon {
    background-color: transparent; }
  .drawer-open .drawer-hamburger-icon:before, .drawer-open .drawer-hamburger-icon:after {
    top: 0; }
  .drawer-open .drawer-hamburger-icon:before {
    transform: rotate(45deg); }
  .drawer-open .drawer-hamburger-icon:after {
    transform: rotate(-45deg); }

.drawer-default {
  background-color: #222;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.5); }
  .drawer-default + .drawer-overlay {
    background-color: #fff; }
  .drawer-default li, .drawer-default a, .drawer-default .drawer-brand {
    position: relative;
    display: block; }
  .drawer-default a {
    text-decoration: none; }
    .drawer-default a:hover, .drawer-default a:focus {
      text-decoration: underline; }
  .drawer-default .drawer-brand a {
    padding: 20px 15px;
    color: #fff;
    font-size: 22px; }
    .drawer-default .drawer-brand a:hover {
      color: #555; }
  .drawer-default .drawer-brand > img {
    display: block; }
  .drawer-default .drawer-footer {
    position: relative;
    padding: 0 15px;
    height: 50px;
    line-height: 50px;
    background-color: transparent; }
    .drawer-default .drawer-footer span:before,
    .drawer-default .drawer-footer span:after {
      content: " ";
      display: block; }
  .drawer-default ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .drawer-default .drawer-menu-item {
    padding: 15px 15px 0;
    font-size: 18px;
    color: #555; }
    .drawer-default .drawer-menu-item.disabled a {
      color: #333; }
      .drawer-default .drawer-menu-item.disabled a:hover, .drawer-default .drawer-menu-item.disabled a:focus {
        cursor: not-allowed;
        color: #333;
        background-color: transparent; }
    .drawer-default .drawer-menu-item a {
      color: #888; }
      .drawer-default .drawer-menu-item a:hover, .drawer-default .drawer-menu-item a:focus {
        color: #fff;
        background-color: transparent; }
      .drawer-default .drawer-menu-item a img {
        max-width: none; }
  .drawer-default .drawer-submenu {
    margin-bottom: 20px; }
    .drawer-default .drawer-submenu:last-child {
      margin-bottom: 0; }
  .drawer-default .drawer-submenu-item {
    padding: 0; }
    .drawer-default .drawer-submenu-item a {
      height: 50px;
      line-height: 50px;
      font-size: 14px; }
      .drawer-default .drawer-submenu-item a:hover, .drawer-default .drawer-submenu-item a:focus {
        color: #fff; }
  .drawer-default .dropdown-menu {
    position: absolute;
    display: none;
    z-index: 5000;
    background-color: #222;
    border: 0;
    box-shadow: none; }
    .drawer-default .dropdown-menu > li > a {
      padding: 0 !important; }
  .drawer-default .dropdown.open > .dropdown-menu {
    position: static;
    display: block;
    float: none;
    width: auto; }
  @media (max-width: 767px) {
    .drawer-default a:hover, .drawer-default a:focus {
      color: #888 !important;
      text-decoration: none; } }

.drawer-navbar-default {
  background-color: #222;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.5); }
  .drawer-navbar-default + .drawer-overlay {
    background-color: #fff; }
  .drawer-navbar-default li, .drawer-navbar-default a, .drawer-navbar-default .drawer-brand {
    position: relative;
    display: block; }
  .drawer-navbar-default a {
    text-decoration: none;
    outline: 0; }
    .drawer-navbar-default a:hover, .drawer-navbar-default a:focus {
      text-decoration: none; }
  .drawer-navbar-default .drawer-brand a {
    padding: 20px 15px;
    color: #fff;
    font-size: 22px; }
    .drawer-navbar-default .drawer-brand a:hover, .drawer-navbar-default .drawer-brand a:focus {
      color: #555; }
  .drawer-navbar-default .drawer-brand > img {
    display: block; }
  .drawer-navbar-default .drawer-footer {
    position: relative;
    padding: 0 15px;
    height: 50px;
    line-height: 50px;
    background-color: transparent; }
    .drawer-navbar-default .drawer-footer span:before,
    .drawer-navbar-default .drawer-footer span:after {
      content: " ";
      display: block; }
  .drawer-navbar-default ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .drawer-navbar-default .drawer-menu.drawer-navbar-right {
    float: none; }
  .drawer-navbar-default .drawer-menu-item {
    padding: 15px 15px 0;
    font-size: 18px;
    color: #555; }
    .drawer-navbar-default .drawer-menu-item.disabled a {
      color: #333; }
      .drawer-navbar-default .drawer-menu-item.disabled a:hover, .drawer-navbar-default .drawer-menu-item.disabled a:focus {
        color: #333;
        background-color: transparent;
        cursor: not-allowed; }
    .drawer-navbar-default .drawer-menu-item a {
      color: #888; }
      .drawer-navbar-default .drawer-menu-item a:hover, .drawer-navbar-default .drawer-menu-item a:focus {
        color: #fff;
        background-color: transparent; }
      .drawer-navbar-default .drawer-menu-item a img {
        max-width: none; }
  .drawer-navbar-default .drawer-submenu {
    margin-bottom: 20px; }
    .drawer-navbar-default .drawer-submenu:last-child {
      margin-bottom: 0; }
  .drawer-navbar-default .drawer-submenu-item {
    padding: 0; }
    .drawer-navbar-default .drawer-submenu-item a {
      height: 50px;
      line-height: 50px;
      font-size: 14px; }
      .drawer-navbar-default .drawer-submenu-item a:hover, .drawer-navbar-default .drawer-submenu-item a:focus {
        color: #fff; }
  .drawer-navbar-default .dropdown-menu {
    position: absolute;
    display: none;
    z-index: 5000;
    top: 100%;
    left: 0;
    background-color: #222;
    border: 0;
    box-shadow: none; }
    .drawer-navbar-default .dropdown-menu > li > a {
      padding: 0 !important; }
  .drawer-navbar-default .dropdown.open > .dropdown-menu {
    display: block; }
  @media (max-width: 767px) {
    .drawer-navbar-default .container {
      padding-left: 0;
      padding-right: 0; }
    .drawer-navbar-default .drawer-menu-item a:hover, .drawer-navbar-default .drawer-menu-item a:focus {
      color: #888;
      text-decoration: none; }
    .drawer-navbar-default .drawer-submenu {
      margin-bottom: 20px; }
      .drawer-navbar-default .drawer-submenu:last-child {
        margin-bottom: 0; }
    .drawer-navbar-default .drawer-submenu-item a:hover, .drawer-navbar-default .drawer-submenu-item a:focus {
      color: #ddd; }
    .drawer-navbar-default .dropdown.open > .dropdown-menu {
      position: static;
      float: none;
      width: auto; } }

.drawer-dropdown .caret,
.drawer-dropdown-hover .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  transition: transform 0.2s ease, opacity 0.2s ease;
  transform: rotate(0deg); }

.drawer-dropdown.open .caret,
.drawer-dropdown-hover.open .caret {
  transform: rotate(180deg); }

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

@media (min-width: 767px) {
  .drawer-responsive.drawer-left .drawer-toggle,
  .drawer-responsive.drawer-right .drawer-toggle,
  .drawer-navbar.drawer-left .drawer-toggle,
  .drawer-navbar.drawer-right .drawer-toggle,
  .drawer-navbar.drawer-top .drawer-toggle {
    display: none;
    visibility: hidden; }

  .drawer-responsive.drawer-left .drawer-main {
    left: 0;
    display: block;
    transform: none; }

  .drawer-responsive.drawer-right .drawer-main {
    right: 0;
    display: block;
    transform: none; } }
@media (min-width: 768px) {
  body.drawer-navbar.drawer-fixed {
    padding-top: 50px; }

  .drawer-responsive {
    background-color: #222; }

  .drawer-responsive .drawer-overlay {
    background-color: #fff; }

  .drawer-responsive .container {
    max-width: 618px; }

  .drawer-responsive.drawer-left .drawer-main,
  .drawer-responsive.drawer-right .drawer-main {
    position: absolute;
    width: 150px !important;
    height: auto !important;
    overflow: visible !important; }

  .drawer-responsive.drawer-left .drawer-main {
    float: left; }
  .drawer-responsive.drawer-left .drawer-overlay {
    margin-left: 150px; }

  .drawer-responsive.drawer-right .drawer-main {
    float: right; }
  .drawer-responsive.drawer-right .drawer-overlay {
    margin-right: 150px; }

  .drawer-navbar.drawer-left .drawer-main,
  .drawer-navbar.drawer-right .drawer-main,
  .drawer-navbar.drawer-top .drawer-main {
    position: relative; }

  .drawer-navbar.drawer-static.drawer-left .drawer-main,
  .drawer-navbar.drawer-static.drawer-right .drawer-main,
  .drawer-navbar.drawer-static.drawer-top .drawer-main {
    z-index: 5000; }

  .drawer-navbar.drawer-fixed.drawer-left .drawer-main,
  .drawer-navbar.drawer-fixed.drawer-right .drawer-main,
  .drawer-navbar.drawer-fixed.drawer-top .drawer-main {
    position: fixed;
    z-index: 5100; }

  .drawer-navbar.drawer-left .drawer-main,
  .drawer-navbar.drawer-right .drawer-main,
  .drawer-navbar.drawer-top .drawer-main {
    display: block;
    top: 0;
    left: 0;
    width: 100% !important;
    height: auto !important;
    overflow: visible !important;
    transform: none; }

  .drawer-navbar-default {
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #ccc; }
    .drawer-navbar-default li, .drawer-navbar-default a, .drawer-navbar-default .drawer-brand {
      float: left; }
    .drawer-navbar-default a {
      padding: 0 15px;
      height: 50px;
      line-height: 50px;
      font-size: 14px; }
    .drawer-navbar-default .drawer-brand a {
      padding: 0 15px;
      color: #555;
      font-size: 90%; }
      .drawer-navbar-default .drawer-brand a:hover, .drawer-navbar-default .drawer-brand a:focus {
        color: #333;
        background-color: #eee; }
    .drawer-navbar-default .drawer-footer {
      display: none; }
    .drawer-navbar-default .drawer-menu.drawer-navbar-right {
      float: right; }
      .drawer-navbar-default .drawer-menu.drawer-navbar-right .dropdown-menu {
        right: 0;
        left: auto; }
    .drawer-navbar-default .drawer-menu-item {
      padding: 0;
      font-size: 14px; }
      .drawer-navbar-default .drawer-menu-item a:hover, .drawer-navbar-default .drawer-menu-item a:focus {
        color: #888;
        background-color: #eee; }
    .drawer-navbar-default .dropdown-menu {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
      .drawer-navbar-default .dropdown-menu > li {
        float: none;
        /* for IE7+ */ }
        .drawer-navbar-default .dropdown-menu > li > a {
          width: 160px;
          padding: 0 15px !important;
          /* conflict by cooker */
          color: #fff;
          background-color: #444;
          border-bottom: 1px solid #555;
          opacity: 0.9; }
          .drawer-navbar-default .dropdown-menu > li > a:hover, .drawer-navbar-default .dropdown-menu > li > a:focus {
            color: #999;
            background-color: #333; } }
@media (min-width: 1200px) {
  .drawer-responsive .container {
    max-width: 768px; }

  .drawer-responsive.drawer-left .drawer-main,
  .drawer-responsive.drawer-right .drawer-main {
    width: 280px !important; }

  .drawer-responsive.drawer-left .drawer-overlay {
    margin-left: 280px; }

  .drawer-responsive.drawer-right .drawer-overlay {
    margin-right: 280px; } }


