// drawer navbar default < 768px
// ======================================
.drawer-navbar-default {
  background-color: $drawer-nav-bg-color;
  box-shadow: inset 0 0px 0px rgba(0,0,0,.5);
  & + .drawer-overlay {
    background-color: #fff;
  }
  li, a, .drawer-brand {
    position: relative;
    display:block;
  }
  a {
    text-decoration: none;
    outline: 0;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .drawer-brand {
    a {
      padding: 20px 15px;
      color: $drawer-hover-color;
      font-size: 22px;
      &:hover,
      &:focus {
        color: $drawer-text-color;
      }
    }
    > img {
      display: block;
    }
  }
  .drawer-footer {
    position: relative;
    padding: 0 15px;
    height: $drawer-nav-height;
    line-height: $drawer-nav-lineHeight;    
    background-color: transparent;
    span:before,
    span:after {
      content: " ";
      display: block;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .drawer-menu.drawer-navbar-right {
      float: none;
    }
  .drawer-menu-item {
    padding: 15px 15px 0;
    font-size: 18px;
    color: $drawer-text-color;
    &.disabled a {
      color: #333;
        &:hover,
        &:focus {
          color: #333;
          background-color: transparent;
          cursor:not-allowed;
        }
    }
    a {
      color: $drawer-link-color;
      &:hover,
      &:focus {
        color: $drawer-hover-color;
        background-color: transparent;
      }
      img {
        max-width: none;
      }
    }
  }
  .drawer-submenu {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .drawer-submenu-item {
    padding: 0;
    a {
      height: $drawer-nav-height;
      line-height: $drawer-nav-lineHeight;
      font-size: 14px;
      &:hover,
      &:focus {
        color: $drawer-hover-color;
      }
    }
  }
// dropdown
  .dropdown-menu {
    position: absolute;
    display: none;
    z-index: $drawer-dropdown-zIndex;
    top: 100%;
    left: 0;
    background-color: $drawer-nav-bg-color;
    border: 0;
    box-shadow: none;
    > li > a {
      padding: 0!important; // conflict by cooker
    }
  }
  .dropdown.open > .dropdown-menu {
    display: block;
  }

  @media (max-width: 767px) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
    .drawer-menu-item a {
      &:hover,
      &:focus {
        color: $drawer-link-color;
        text-decoration: none;
      }
    }
    .drawer-submenu {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .drawer-submenu-item {
      a {
        &:hover,
        &:focus {
          color: $drawer-dropdown-link-color;
        }
      }
    }
    .dropdown.open > .dropdown-menu {
      position: static;
      float: none;
      width: auto;
    }
  }
}
