$drawer-width-phone:         280px;
$drawer-width-tablet:        150px;
$drawer-width-desktop:       $drawer-width-phone;

$drawer-nav-height:          50px;
$drawer-nav-lineHeight:      50px;

$drawer-nav-bg-color:        #222;
$drawer-navbar-bg-color:     #fff; // navbar desktop only
$drawer-hamburger-bg-color:  #ddd;
$drawer-text-color:          #555;
$drawer-link-color:          #888;
$drawer-hover-color:         #fff;
$drawer-dropdown-link-color: #ddd;
$drawer-dropdown-hover-color:#fff;

$drawer-main-zIndex:         5300;
$drawer-upper-zIndex:        5200;
$drawer-toggle-zIndex:       5100;
$drawer-navbar-static-zIndex:5000;
$drawer-navbar-fixed-zIndex: 5100;
$drawer-dropdown-zIndex:     5000;
$drawer-hamburger-zIndex:    5400;

$drawer-ease:                cubic-bezier(0.190, 1.000, 0.220, 1.000);
$drawer-duration:            .4s;

@import "core";
@import "hamburger";
@import "nav";
@import "navbar";
@import "dropdown";
@import "accessibility";
@import "responsive";
